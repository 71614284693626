:root {
  --white: #fff;
  --textBlack: #141414;
  --textGrey: #464E5B;
  --blue: #F5B335;
  --hoverBlue: #F5B335;
  --black:#000;
}

@media(min-width:600px) {
  .buttons-container > .MuiButton-root {
      padding: 16px 58px !important;
  }
}

.rmdp-container {
  width: 100%;
}

/* Base date picker*/
.quara-picker.rmdp-wrapper {
  background: var(--white);
  font-family: inherit;
  box-shadow: 0 1px 2px rgba(0, 14, 51, 0.25);
  border-radius: 8px;
  border: 1px solid #EDEEEF;
  overflow: visible;
  color: var(--textBlack);
  display: flex;
  flex-direction: column;
  width: 377px;
  padding: 16px 12px 12px 12px;
}

/* font-family: 'Lexend'; */

body[dir="rtl"] .quara-picker.rmdp-wrapper {
  font-family: 'Cairo';
}

.quara-picker .rmdp-calendar {
  padding-bottom: 16px;
}

.quara-picker .rmdp-header {
  position: relative;
  font-size: 24px;
  font-weight: bold;
}

.quara-picker .rmdp-week-day {
  width:50px;
  height: 44px;
  display: inline-flex;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color:#3C3C434D;
}

.quara-picker .rmdp-day {
  min-width: 48px;
  width:48px;
  height: 44px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: var(--white);
  margin: 2px;
  color: var(--black)
}

.quara-picker.rmdp-day .rmdp-today .rmdp-selected span.sd,
.quara-picker .rmdp-day.rmdp-selected span:not(.highlight),
.quara-picker.rmdp-day .rmdp-today .rmdp-selected span.sd:hover,
.quara-picker .rmdp-day.rmdp-selected span:not(.highlight):hover {
  background-color: var(--blue);
  color: white;
}

.quara-picker .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden):not(.rmdp-selected) span:hover {
  background-color: #f0f0f0;
  color: var(--black);
}

.quara-picker div.rmdp-day.rmdp-today:not(.rmdp-selected) span {
  background-color: white;
  color: var(--black);
}

.quara-picker .rmdp-day span {
  border-radius: 10px;
}

.quara-picker .buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

/*navigation arrows*/
.quara-picker span.rmdp-arrow-container.rmdp-left.disabled,
.quara-picker span.rmdp-arrow-container.rmdp-right.disabled {
  display: none;
}

.quara-picker span.rmdp-arrow-container.rmdp-left,
.quara-picker span.rmdp-arrow-container.rmdp-right {
  border-radius: 100%;
  background-color: white;
  background-size: contain;
  box-shadow: 0 1px 1px rgba(0, 14, 51, 0.05);
  width: 44px;
  height: 44px;
}

.quara-picker span.rmdp-arrow-container.rmdp-left i,
.quara-picker span.rmdp-arrow-container.rmdp-right i {
  display: none;
}

body[dir="rtl"] .quara-picker span.rmdp-arrow-container.rmdp-right,
body[dir="ltr"] .quara-picker span.rmdp-arrow-container.rmdp-left {
  background: white url("../../assets/icons/calendar_arrow_left.svg") no-repeat center;
  top: 16px;
  left: 3px;
}

body[dir="rtl"] .quara-picker span.rmdp-arrow-container.rmdp-left,
body[dir="ltr"] .quara-picker span.rmdp-arrow-container.rmdp-right {
  background: white url("../../assets/icons/calendar_arrow_right.svg") no-repeat center;
  right: 3px;
  top: 16px;
}

.quara-picker  .rmdp-header-values span {
  display: inline-block;
  height: 44px;
  margin: 0 5px;
  background: white;
  box-shadow: 0px 1px 1px rgb(0 14 51 / 5%);
  border-radius: 6px;
  padding: 0 0 0 5px;
}

body[dir="ltr"] .quara-picker .rmdp-header-values span:nth-child(1)::after,
body[dir="ltr"] .quara-picker .rmdp-header-values span:nth-child(2)::after,
body[dir="rtl"] .quara-picker .rmdp-header-values span:nth-child(2)::before,
body[dir="rtl"] .quara-picker .rmdp-header-values span:nth-child(1)::before {
  display: inline-block;
  position: relative;
  width: 1px;
  height: 11px;
  background: white;
  content: '';
  top: 8px;
  border-top: 6px solid #F5B335;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}

body[dir="ltr"] .quara-picker .rmdp-header-values span:nth-child(1)::after {
  right: 5px;
  clip-path: polygon(54% 0, 72% 0, 81% 3%, 51% 40%, 0 100%, 0 34%, 51% 40%, 23% 3%, 33% 0); /* With tail */
}

body[dir="rtl"] .quara-picker .rmdp-header-values span:nth-child(1)::before,
body[dir="ltr"] .quara-picker .rmdp-header-values span:nth-child(2)::after {
  right: 0;
}

body[dir="ltr"] .quara-picker .rmdp-header-values span:nth-child(2)::after,
body[dir="rtl"] .quara-picker .rmdp-header-values span:nth-child(2)::before,
body[dir="rtl"] .quara-picker .rmdp-header-values span:nth-child(1)::before {
  clip-path: polygon(54% 0, 72% 0, 81% 3%, 51% 40%, 51% 40%, 23% 3%, 33% 0); /* Without tail */
}

body[dir="rtl"] .quara-picker .rmdp-header-values span:nth-child(1)::after {
  display: inline-block;
  position: relative;
  left: 6px;
  content: '';
  height: 6px;
  width: 6px;
  background-color: white;
}

/*Mobile date picker*/

.quara-picker .rmdp-action-buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 21px 50px 21px;
}

.quara-picker .rmdp-action-buttons:after {
  position: absolute;
  content: "";
  width: 134px;
  height: 5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #6B727C;
  border-radius: 100px;
  bottom: 8px;
}

.quara-picker .rmdp-action-buttons .rmdp-button.rmdp-action-button {
  width: 156px;
  padding: 14px 20px;
  font-family: inherit;
  font-size: 1.6rem;
  margin-top: 0;
  margin-bottom: 0;
}

.quara-picker .rmdp-action-buttons .rmdp-button.rmdp-action-button:first-child {
  color: #FFFFFF;
  border-radius: 8px;
  background-color: #F5B335;
  height: 58px;
}

.quara-picker .rmdp-action-buttons .rmdp-button.rmdp-action-button:nth-last-child(1) {
  background-color: #F6F6F7;
  border-radius: 8px;
  color: #2C2926;
  height: 58px;
}

@media(max-width: 600px) {
  .quara-picker.rmdp-wrapper.rmdp-mobile {
    width: 375px;
    padding: 0;
  }

  .quara-picker.rmdp-mobile .rmdp-day {
    width:48px;
    height: 44px;
  }

  .quara-picker .rmdp-day-picker,
  .quara-picker .rmdp-top-class {
    display: block !important;
  }

  .quara-picker .rmdp-day {
    margin: 0px;
  }

  .quara-picker .buttons-container {
    padding: 0 21px 50px 21px;
  }
  .quara-picker .buttons-container button {
    width: 156px;
  }

  .quara-picker .rmdp-border-top {
    border: none;
  }

  .quara-picker .rmdp-header-values {
    font-size: 24px;
  }
}


/* .quara-picker .rmdp-day {
  min-width: 48px;
  width:48px;
  height: 44px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: var(--white);
  margin: 2px;
  color: var(--black)
} */